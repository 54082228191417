/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../../components/Layout'
import ThemeWrapper from '../../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Image, ColumnCover, ColumnDiv, Title, Subtitle, Button, Text } from '@swp/components'
import SiteHeader from '../../components/en/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <SiteHeader />

        <Column className="pb--50 pt--80" name={"introduction"} layout={"l30"}>
          
          <ColumnWrap className="column__flex js-anim  --anim7 --anim-s5 --center el--1 --full pt--50 flex--center" anim={"7"} animS={"5"} columns={"1"} fullscreen={true}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s5" anim={"2"} animS={"5"}>
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/11212/9750e69ce2424204b63be7da9080c302_s=860x_.jpg"} svg={false} ratio={"2:3"} sizes={"100vw"} style={{"maxWidth":670}} srcSet={"https://cdn.swbpg.com/t/11212/9750e69ce2424204b63be7da9080c302_s=350x_.jpg 350w, https://cdn.swbpg.com/t/11212/9750e69ce2424204b63be7da9080c302_s=660x_.jpg 660w, https://cdn.swbpg.com/t/11212/9750e69ce2424204b63be7da9080c302_s=860x_.jpg 860w, https://cdn.swbpg.com/t/11212/9750e69ce2424204b63be7da9080c302_s=1400x_.jpg 1400w"} content={null} position={null}>
              </Image>

              <ColumnCover >
                
                <ColumnWrapper className="js-anim  --anim3 --anim-s5 --center pt--20 flex--top" anim={"3"} animS={"5"}>
                  
                  <ColumnDiv >
                    
                    <Title className="title-box fs--185 lh--1" content={"Theresa & Martin"}>
                    </Title>

                    <Subtitle className="subtitle-box fs--62 lh--1 mt--0" content={"14 – 7 – 2021"}>
                    </Subtitle>

                  </ColumnDiv>

                </ColumnWrapper>

              </ColumnCover>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--80 pt--80" name={"text"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s5 --center el--1" anim={"2"} animS={"5"} style={{"maxWidth":1090}} columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center fs--102" content={"We're getting married! Come share the <span style=\\\"font-style: italic;\\\">most beautiful day</span> of our lives with us!"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--40 pt--40" name={"photogallery"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper anim={null} animS={null}>
              
              <Image alt={""} src={"https://cdn.swbpg.com/t/11212/9b64ca21ca324b498fd12bd9d5ebcd80_s=860x_.jpg"} svg={false} ratio={"3:4"} sizes={"100vw"} style={{"maxWidth":""}} srcSet={"https://cdn.swbpg.com/t/11212/9b64ca21ca324b498fd12bd9d5ebcd80_s=350x_.jpg 350w, https://cdn.swbpg.com/t/11212/9b64ca21ca324b498fd12bd9d5ebcd80_s=660x_.jpg 660w, https://cdn.swbpg.com/t/11212/9b64ca21ca324b498fd12bd9d5ebcd80_s=860x_.jpg 860w, https://cdn.swbpg.com/t/11212/9b64ca21ca324b498fd12bd9d5ebcd80_s=1400x_.jpg 1400w"} content={null} position={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="mt--50" anim={null} animS={null}>
              
              <Image alt={""} src={"https://cdn.swbpg.com/t/11212/d97de414627a4649834e3b959027003b_s=860x_.jpg"} svg={false} ratio={"3:4"} sizes={"100vw"} style={{"maxWidth":""}} srcSet={"https://cdn.swbpg.com/t/11212/d97de414627a4649834e3b959027003b_s=350x_.jpg 350w, https://cdn.swbpg.com/t/11212/d97de414627a4649834e3b959027003b_s=660x_.jpg 660w, https://cdn.swbpg.com/t/11212/d97de414627a4649834e3b959027003b_s=860x_.jpg 860w, https://cdn.swbpg.com/t/11212/d97de414627a4649834e3b959027003b_s=1400x_.jpg 1400w"} content={null} position={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper anim={null} animS={null}>
              
              <Image alt={""} src={"https://cdn.swbpg.com/t/11212/9946ee6d9d5f4aadbf5093503594cba7_s=860x_.jpg"} svg={false} ratio={"3:4"} sizes={"100vw"} style={{"maxWidth":""}} srcSet={"https://cdn.swbpg.com/t/11212/9946ee6d9d5f4aadbf5093503594cba7_s=350x_.jpg 350w, https://cdn.swbpg.com/t/11212/9946ee6d9d5f4aadbf5093503594cba7_s=660x_.jpg 660w, https://cdn.swbpg.com/t/11212/9946ee6d9d5f4aadbf5093503594cba7_s=860x_.jpg 860w, https://cdn.swbpg.com/t/11212/9946ee6d9d5f4aadbf5093503594cba7_s=1400x_.jpg 1400w"} content={null} position={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--80 pt--80" name={"info"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s5 --center el--1" anim={"2"} animS={"5"} style={{"maxWidth":1090}} columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box title-box--center fs--102" content={"14.7.2021<br>"}>
              </Title>

              <Title className="title-box title-box--center fs--102 mt--10" content={"Lake Erie — Racine, WI"}>
              </Title>

              <Button className="btn-box btn-box--hvr3 btn-box--cbtn1 btn-box--sbtn2 btn-box--cColor2 mt--50" innerClassName="pt--0 pb--02 pl--0 pr--0" href={"/en/accommodation"} content={"Accommodation"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--40" name={"fotka"}>
          
          <ColumnWrap className="column__flex --center el--1" style={{"maxWidth":1150}} columns={"1"}>
            
            <ColumnWrapper anim={null} animS={null}>
              
              <Image alt={""} src={"https://cdn.swbpg.com/t/11212/43bf8fe0c7ce487288585d6049e4ff61_s=860x_.jpg"} svg={false} ratio={"4:3"} sizes={"100vw"} style={{"maxWidth":""}} srcSet={"https://cdn.swbpg.com/t/11212/43bf8fe0c7ce487288585d6049e4ff61_s=350x_.jpg 350w, https://cdn.swbpg.com/t/11212/43bf8fe0c7ce487288585d6049e4ff61_s=660x_.jpg 660w, https://cdn.swbpg.com/t/11212/43bf8fe0c7ce487288585d6049e4ff61_s=860x_.jpg 860w, https://cdn.swbpg.com/t/11212/43bf8fe0c7ce487288585d6049e4ff61_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/11212/43bf8fe0c7ce487288585d6049e4ff61_s=2000x_.jpg 2000w"} content={null} position={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--80 pt--80" name={"information"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s5 --center el--1" anim={"2"} animS={"5"} style={{"maxWidth":1090}} columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box title-box--center fs--102 mt--10" content={"Schedule"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"information-2"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--top" style={{"maxWidth":900}} columns={"2"}>
            
            <ColumnWrapper className="js-anim  --anim5 --anim-s5 --left" anim={"5"} animS={"5"}>
              
              <Title className="title-box" content={"14:00"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="--left mt--0 pt--16" anim={null}>
              
              <Text className="text-box" content={"Click and start writing. This is a text area. If needed, the color, font size, line spacing, text alignment and maximum width of a block of text may be customized. Click and start writing. This is a text area."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--2 mt--30 flex--top" style={{"maxWidth":900}} columns={"2"}>
            
            <ColumnWrapper className="js-anim  --anim5 --anim-s5 --left" anim={"5"} animS={"5"}>
              
              <Title className="title-box" content={"15:00"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="--left mt--0 pt--16">
              
              <Text className="text-box" content={"Click and start writing. This is a text area. If needed, the color, font size, line spacing, text alignment and maximum width of a block of text may be customized. Click and start writing. This is a text area."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--2 mt--30 flex--top" style={{"maxWidth":900}} columns={"2"}>
            
            <ColumnWrapper className="js-anim  --anim5 --anim-s5 --left" anim={"5"} animS={"5"}>
              
              <Title className="title-box" content={"23:00"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="--left mt--0 pt--16">
              
              <Text className="text-box" content={"Click and start writing. This is a text area. If needed, the color, font size, line spacing, text alignment and maximum width of a block of text may be customized. Click and start writing. This is a text area."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--60 pt--60" name={"spacer"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s5 --center el--1" anim={"2"} animS={"5"} style={{"maxWidth":1090}} columns={"1"}>
          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"photogallery–2"}>
          
          <ColumnWrap className="column__flex --center el--2" style={{"maxWidth":""}} columns={"2"}>
            
            <ColumnWrapper className="mt--80" anim={null} animS={null}>
              
              <Image alt={""} src={"https://cdn.swbpg.com/t/11212/79ca3c3583914171a63a231a4cef3710_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes={"100vw"} style={{"maxWidth":""}} srcSet={"https://cdn.swbpg.com/t/11212/79ca3c3583914171a63a231a4cef3710_s=350x_.jpg 350w, https://cdn.swbpg.com/t/11212/79ca3c3583914171a63a231a4cef3710_s=660x_.jpg 660w, https://cdn.swbpg.com/t/11212/79ca3c3583914171a63a231a4cef3710_s=860x_.jpg 860w, https://cdn.swbpg.com/t/11212/79ca3c3583914171a63a231a4cef3710_s=1400x_.jpg 1400w"} content={null} position={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper anim={null} animS={null}>
              
              <Image alt={""} src={"https://cdn.swbpg.com/t/11212/6de6e231b77b43069228cac19ff0a1ce_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes={"100vw"} style={{"maxWidth":""}} srcSet={"https://cdn.swbpg.com/t/11212/6de6e231b77b43069228cac19ff0a1ce_s=350x_.jpg 350w, https://cdn.swbpg.com/t/11212/6de6e231b77b43069228cac19ff0a1ce_s=660x_.jpg 660w, https://cdn.swbpg.com/t/11212/6de6e231b77b43069228cac19ff0a1ce_s=860x_.jpg 860w, https://cdn.swbpg.com/t/11212/6de6e231b77b43069228cac19ff0a1ce_s=1400x_.jpg 1400w"} content={null} position={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--80 pt--80" name={"footer"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s5 --center el--1" anim={"2"} animS={"5"} style={{"maxWidth":1090}} columns={"1"}>
            
            <ColumnWrapper >
              
              <Button className="btn-box btn-box--hvr3 btn-box--cbtn1 btn-box--sbtn2 btn-box--cColor2" innerClassName="pt--0 pb--02 pl--0 pr--0" href={"/en/rsvp"} content={"RSVP"}>
              </Button>

              <Button className="btn-box btn-box--hvr3 btn-box--cbtn1 btn-box--sbtn2 btn-box--cColor2 mt--16" innerClassName="pt--0 pb--02 pl--0 pr--0" href={"/en/gift-registry"} content={"Gift registry"}>
              </Button>

              <Button className="btn-box btn-box--hvr3 btn-box--cbtn1 btn-box--sbtn2 btn-box--cColor2 mt--16" innerClassName="pt--0 pb--02 pl--0 pr--0" href={"/en/photogallery"} content={"Photogallery"}>
              </Button>

              <Image className="mt--40" alt={""} src={"https://cdn.swbpg.com/o/11212/0cc2932258564e9dadfbd3f8a0b406da.svg"} svg={false} href={"https://facebook.com"} sizes={"100vw"} style={{"maxWidth":32}} srcSet={""} content={null}>
              </Image>

              <Image className="mt--20" alt={""} src={"https://cdn.swbpg.com/o/11212/c052f05d66c6491c98f55819ed14c078.svg"} svg={false} href={"https://instagram.com"} sizes={"100vw"} style={{"maxWidth":32}} srcSet={""} content={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--80 pt--40" name={"footer-2"}>
          
          <ColumnWrap className="column__flex --center el--1" anim={null} animS={null} style={{"maxWidth":1090}} columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box title-box--center fs--102 mt--10" content={"We look forward to seeing you!"}>
              </Title>

              <Text className="text-box text-box--center mt--10" content={"<a href=\"https://saywebpage.com\">Create your own website</a>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}